






























































































































































































import SiteSection from '@/components/SiteSection.vue'
import PropertyPreviewCardSkeleton from '@/components/PropertyPreviewCardSkeleton.vue'
import PropertyPreviewCard from '@/components/PropertyPreviewCard.vue'
import { useProperties } from '@/composition/properties'
import { Swiper } from 'vue-awesome-swiper'
import { computed, defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteSectionProperties',

  components: {
    SiteSection,
    PropertyPreviewCardSkeleton,
    PropertyPreviewCard,
  },

  setup() {
    const rowsPerPageRecommendedProperties = 3
    const {
      data: recommendedProperties,
      isLoading: isLoadingRecommendedProperties,
    } = useProperties({
      pageNumber: 1,
      rowsPerPage: rowsPerPageRecommendedProperties,
      isRecommendation: true,
      isDeleted: false,
    })

    const rowsPerPageFeaturedProperties = 3
    const { data: featuredProperties, isLoading: isLoadingFeaturedProperties } =
      useProperties({
        pageNumber: 1,
        rowsPerPage: rowsPerPageFeaturedProperties,
        isFeatureListing: true,
        isDeleted: false,
      })

    const recommendedPropertiesSwiper = ref<InstanceType<typeof Swiper> | null>(
      null
    )
    const featuredPropertiesSwiper = ref<InstanceType<typeof Swiper> | null>(
      null
    )

    const swiperOptions = computed(() => ({
      slidesPerView: 'auto',
      observer: true,
      observeParents: true,
    }))

    return {
      recommendedProperties,
      rowsPerPageRecommendedProperties,
      isLoadingRecommendedProperties,
      featuredProperties,
      rowsPerPageFeaturedProperties,
      isLoadingFeaturedProperties,
      recommendedPropertiesSwiper,
      featuredPropertiesSwiper,
      swiperOptions,
    }
  },
})
