





































































































import SiteSection from '@/components/SiteSection.vue'
import BenefitCard from '@/components//BenefitCard.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteSectionWhyChooseUs',

  components: {
    SiteSection,
    BenefitCard,
  },

  setup() {
    return {}
  },
})
