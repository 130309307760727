
























































































































import SiteSection from '@/components/SiteSection.vue'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'SiteSectionHero',

  components: {
    SiteSection,
  },

  setup() {
    const defaultCtaBoxClasses = 'bg-black bg-opacity-60'
    const currentCtaBoxClasses = ref(defaultCtaBoxClasses)

    function onLinkMouseEnter(classes: string) {
      currentCtaBoxClasses.value = classes
    }

    function onLinkMouseLeave() {
      currentCtaBoxClasses.value = defaultCtaBoxClasses
    }

    return {
      currentCtaBoxClasses,
      onLinkMouseEnter,
      onLinkMouseLeave,
    }
  },
})
