






































































































































import SiteSection from '@/components/SiteSection.vue'
import { Location } from 'vue-router'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CostessaBanner',

  components: {
    SiteSection,
  },

  setup() {
    function getCostessaRouteTo(): Location {
      return {
        name: 'Costessa',
      }
    }

    return {
      getCostessaRouteTo,
    }
  },
})
