









import CostessaBanner from '@/components/CostessaBanner.vue'
import SiteSectionHero from '@/components/SiteSectionHero.vue'
import SiteSectionWhyChooseUs from '@/components/SiteSectionWhyChooseUs.vue'
import SiteSectionProperties from '@/components/SiteSectionProperties.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Home',

  components: {
    CostessaBanner,
    SiteSectionHero,
    SiteSectionWhyChooseUs,
    SiteSectionProperties,
  },
})
